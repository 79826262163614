.App {
  text-align: center;
}

.left-align {
  text-align: left;
}

h4 {
  margin-bottom: 20px !important;
}

a {
  text-decoration: none;
  position: relative;
  padding: 16px 0;
  transition: color 0.1s, background-color 0.1s;
  color: #000;
}
a:hover {
  color: #4dd0e1;
}
a:focus, a:active {
  color: #00bcd4;
}

.nav-link::before {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left:0%;
  height: 3px;
  width: 100%;
  background-color: #343a40;
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  transition: color 0.1s, -webkit-transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

a:active::before {
  background-color: #343a40;
}

a:hover::before,
a:focus::before {
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.deactivate-effect.a::before {
  height: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media (min-width: 991.98px) {
  
  .shrink-lg-up-55 {
  width: 55%;
  }

  .shrink-lg-up-45 {
    width: 45%;
  }

}

@media (max-width: 991.98px) {

  .shrink-md-down-h3 {
    font-size: 1.1rem;
    line-height: 30px;
  }

  .shrink-md-down-max80 {
    width: 80%;
    }

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
